/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import InputSelect from '../../../../components/input-select';
import { type TextualGenreType } from '../../../../class/types/new-writing-hypothesi/textual-genre';
import TextualGenreApi from '../../../../class/new-writing-hypothesi/textual-genre/textual-genre-api';
import { useParams } from 'react-router-dom';
import NewWritingHypothesisApi from '../../../../class/new-writing-hypothesi/new-writing-hypothesis/new-writing-hypothesis-api';
import { type WritingHypothesisResultType, type StudentWritingLevelType } from '../../../../class/types/new-writing-hypothesi/new-writing-hypothesis';
import NewWritingHypothesisElement from '../../../../components/new-writing-hypothesis/new-writing-hypothesis';
import printTableNewWritingHypothesis from './pint-table';
import LoadContent from '../../../../components/Load-content';
import RuleVerification from '../../../../components/rule-verification';

interface NewWritingHypothesisTableProps {
  display: string
  roomInfo: any
}

export default function NewWritingHypothesisTable({ display, roomInfo }: NewWritingHypothesisTableProps): React.ReactElement {
  const [textualGenres, setTextualGenres] = useState<TextualGenreType[]>([]);
  const [studentWritingLevel, setStudentWritingLevel] = useState<StudentWritingLevelType[] | null>(null);
  const [textualGenresSelected, setTextualGenresSelected] = useState<TextualGenreType | null>(null);
  const [deleteOptions, setDeleteOptions] = useState<boolean>(false);
  const [writingHypothesiResult, setWritingHypothesiResult] = useState<WritingHypothesisResultType | null>();

  const [isLoadingResult, setIsLoadingResult] = useState(false);

  const [isLoadingWritingLevel, setIsLoadingWritingLevel] = useState(false);

  const [isLoadingInit, setIsLoadingInit] = useState(false);

  const { id } = useParams();

  const cbSelected = (option: string) => {
    const textualGenre = textualGenres.find(g => g.genre.includes(option));

    if (!textualGenre) {
      return;
    }

    setTextualGenresSelected(textualGenre);
    getStudents(textualGenre);

    if (!textualGenre?.['writing-hypothesi-result']) {
      setWritingHypothesiResult(null);
      return;
    }

    setWritingHypothesiResult(textualGenre['writing-hypothesi-result']);
  }

  const initWritingHypothesis = () => {
    if (!textualGenresSelected || !id) {
      return;
    }

    const newWritingHypothesisApi = new NewWritingHypothesisApi();

    setIsLoadingInit(true);
    newWritingHypothesisApi.initWritingHypothesis(id, roomInfo.twoMonths, textualGenresSelected.id).then(response => {
      const textualGenre = response.find(g => g.id.includes(textualGenresSelected.id));

      if (!textualGenre) {
        return;
      }

      setIsLoadingInit(false);
      setTextualGenres(response)
      getStudents(textualGenre);
    }).catch(e => {

    });
  }

  const getStudents = (textualGenre: TextualGenreType) => {
    const students: StudentWritingLevelType[] = [];

    if (!textualGenre['writing-hypothesi']) {
      setStudentWritingLevel(null);
      return;
    }

    Object.keys(textualGenre['writing-hypothesi']).forEach(key => {
      if (!textualGenre['writing-hypothesi']) {
        return;
      }
      students.push(textualGenre['writing-hypothesi'][key]);
    });

    setStudentWritingLevel(students);
  }

  const printTable = () => {
    if (!textualGenresSelected || !studentWritingLevel) {
      return;
    }
    printTableNewWritingHypothesis(textualGenresSelected, studentWritingLevel, roomInfo)
  }

  const generateWritingHypothesis = () => {
    const newWritingHypothesis = new NewWritingHypothesisApi();

    if (!id || !textualGenresSelected) {
      return;
    }

    setIsLoadingResult(true);
    newWritingHypothesis.generateWritingHypothesis(id, roomInfo.twoMonths, textualGenresSelected.id).then(response => {
      setWritingHypothesiResult(response);
      setIsLoadingResult(false);
    }).catch(e => {
      //
    });
  }

  useEffect(() => {
    const textualGenresApi = new TextualGenreApi();

    if (display === 'false' || textualGenres.length > 0) {
      return;
    }

    if (!id) {
      return;
    }

    setIsLoadingWritingLevel(true);
    textualGenresApi.getAll(id, roomInfo.twoMonths).then(response => {
      setIsLoadingWritingLevel(false);
      setTextualGenres(response);
    }).catch(e => {

    });
  }, [display, roomInfo]);

  useEffect(() => {
    if (display !== 'false') {
      return;
    }

    setTextualGenresSelected(null);
    setStudentWritingLevel(null);

    setDeleteOptions(true);

    setTimeout(() => {
      setDeleteOptions(false);
    }, 200);
  }, [display])

  return (
    <div className='new-writing-hypothesis-table'>
      <div className='new-writing-hypothesis-table-header'>
        <h1>Avaliação Diagnostica - {roomInfo.twoMonthsName}</h1>
        {
          isLoadingInit
            ? (
              <LoadContent
                isLoading={isLoadingInit}
                style={{
                  button: {
                    fontSize: 25,
                  },
                  container: {
                    width: 25,
                  }
                }}
              />
            )
            : (!studentWritingLevel
              ? (
                !textualGenresSelected ? '' : <button className='button' onClick={initWritingHypothesis}>Iniciar Avaliação</button>
              )
              : '')
        }
      </div>
      <div className='new-writing-hypothesis-table-middle'>
        <InputSelect
          title=''
          defaultValue='Avaliações'
          cb={cbSelected}
          deleteAll={deleteOptions}
          options={textualGenres?.map(value => value.genre) ?? []}
        />
        <div className='new-writing-hypothesis-table-middle-result'>
          {!studentWritingLevel
            ? ''
            : (
              !textualGenresSelected
                ? ''
                : (
                  <>
                    <h1>Não Realizado: {writingHypothesiResult?.NR ?? 0}</h1>
                    <h1>Alunos avaliados: {writingHypothesiResult?.studentAvailable ?? 0}</h1>
                  </>
                )
            )}
        </div>
        <div className='button-content'>
          {!studentWritingLevel
            ? ''
            : (
              !textualGenresSelected
                ? ''
                : (
                  <>
                    {isLoadingResult
                      ? (
                        <LoadContent
                          isLoading={isLoadingResult}
                          style={{
                            button: {
                              fontSize: 25,
                            },
                            container: {
                              width: 25,
                              height: 25,
                            }
                          }}
                        />
                      )
                      : (
                        <RuleVerification rule={['coordenador']}>
                          <button className='button' onClick={generateWritingHypothesis}>Gerar Resultado</button>
                        </RuleVerification>
                      )}
                    <button className='button' onClick={printTable}>Imprimir</button>
                  </>
                )
            )}
        </div>
      </div>
      <div className='new-writing-hypothesis-table-body'>
        <div className='new-writing-hypothesis-table-body-header'>
          <ul>
            <li>N°</li>
            <li>Nome do Aluno</li>
            <li>Níveis</li>
          </ul>
        </div>
        <div className='new-writing-hypothesis-table-body-table'>
          {isLoadingWritingLevel
            ? (
              <LoadContent
                isLoading={isLoadingWritingLevel}
                style={{
                  container: {
                    marginTop: '10rem',
                  }
                }}
              />
            )
            : !studentWritingLevel
              ? (
                <div className='img-table-empty-container'>
                  <img className='img-table-empty' src={require('./img/undraw_Pie_chart_re_bgs8.png')} alt="" width={500} />
                  <h1>Selecione um avaliação e elabore ou visualize uma hipótese de escrita.</h1>
                </div>
              )
              : (studentWritingLevel.map((value, index) => {
                return <NewWritingHypothesisElement key={value.id} index={index} studentWritingLevel={value} textualGenre={textualGenresSelected ?? {} as unknown as TextualGenreType} roomInfo={roomInfo} setTextualGenres={setTextualGenres} setTextualGenresSelected={setTextualGenresSelected} />
              }))}
        </div>
      </div>
    </div>
  )
}
