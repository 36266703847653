/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
import JSPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { type StudentWritingLevelType } from '../../../../../class/types/new-writing-hypothesi/new-writing-hypothesis';
import { type TextualGenreType } from '../../../../../class/types/new-writing-hypothesi/textual-genre';
const img = require('./img/header-img.png');

export default function printTableNewWritingHypothesis(textualGenre: TextualGenreType, studentWritingLevel: StudentWritingLevelType[], roomInfo: any): void {
  const doc = new JSPDF({
    orientation: 'landscape',
  });

  doc.addImage(img, 'PNG', 50, 5, 180, 0);

  autoTable(doc, {
    startY: 40,
    headStyles: {
      fillColor: '#118951'
    },
    head: [
      ['', `Avaliação: ${textualGenre.genre} - Bimestre: ${roomInfo.twoMonthsName}`, `Emissão: ${(new Date()).toLocaleDateString('pt-BR', { timeZone: 'UTC', dateStyle: 'short'})}`],
      ['Nº', 'Nome do Aluno', 'Nível Escrita'],
    ],
    body: (() => {
      const bodyArray: string[][] = [];

      studentWritingLevel.forEach((student, index) => {
        bodyArray.push([
          `${index + 1}`,
          student.name,
          student.writingLevel.tag
        ])
      });

      return bodyArray;
    })()
  });

  doc.save(`Avaliação ${roomInfo.twoMonthsName} ${textualGenre.genre}.pdf`);
}
