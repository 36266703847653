/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useParams } from 'react-router-dom';
import { type TextualGenreType } from '../../../../class/types/new-writing-hypothesi/textual-genre';
import TextualGenreApi from '../../../../class/new-writing-hypothesi/textual-genre/textual-genre-api';
import InputSelect from '../../../../components/input-select';
import Chart from 'react-apexcharts';

interface GraphProps {
  roomInfo: any
  display: string
}

export default function GraphNewWritingHypothesis({ display, roomInfo }: GraphProps): React.ReactElement {
  const [textualGenres, setTextualGenres] = useState<TextualGenreType[]>([]);
  const [textualGenreSelected, setTextualGenreSelected] = useState<TextualGenreType | null>(null);
  const [labels, setLabels] = useState<string[]>(['*']);
  const [series, setSeries] = useState<number[]>([100]);
  const [colors, setColors] = useState<string[]>(['#fff']);
  const [displayChart, setDisplayChart] = useState(false);

  const [deleteOption, setDeleteOption] = useState<boolean>(false);

  const { id } = useParams();

  const cb = (option: string) => {
    const textualGenre = textualGenres.find(g => g.genre.includes(option));

    if (!textualGenres) {
      setDisplayChart(false);
      return;
    }

    if (!textualGenre?.['writing-hypothesi-result']) {
      setDisplayChart(false);
      return;
    }

    setTextualGenreSelected(textualGenre);

    const graphInfo: {
      colors: string[]
      labels: string[]
      series: number[]

    } = {
      colors: [],
      labels: [],
      series: [],

    }
    textualGenre['writing-hypothesi-result'].tags.map(t => {
      graphInfo.colors.push(t.color);
      graphInfo.labels.push(t.tag);
      graphInfo.series.push(t.count ?? 0);

      return t;
    });

    setDisplayChart(true);

    setLabels(graphInfo.labels);
    setColors(graphInfo.colors);
    setSeries(graphInfo.series);
  }

  useEffect(() => {
    if (!id || !roomInfo.twoMonths) {
      return;
    }

    const textualGenreApi = new TextualGenreApi();

    textualGenreApi.getAll(id, roomInfo.twoMonths).then(response => {
      setTextualGenres(response);
    }).catch(e => {
      //
    });

    setDeleteOption(true);
    setLabels(['']);
    setSeries([100]);
    setColors(['#fff']);

    setTimeout(() => {
      setDeleteOption(false);
    }, 200);

    setDisplayChart(false);
  }, [display, roomInfo]);
  return (
    <div className='new-writing-hypothesi-graph'>
      <div className='new-writing-hypothesi-graph-header'>
        <h1>Gráfico Avaliação Diagnostica - {roomInfo.twoMonthsName}</h1>
      </div>
      <div className='new-writing-hypothesi-graph-middle'>
        <InputSelect
          options={textualGenres.map(g => g.genre)}
          title=''
          defaultValue='Avaliação'
          cb={cb}
          deleteAll={deleteOption}
        />
      </div>
      <div className='new-writing-hypothesi-graph-content'>
        {!displayChart
          ? (
            <img src={require('./img/graph-img.png')} alt="" />
          )
          : (
            <Chart
              className={`graphic-content display-${String(displayChart)}`}
              type='pie'
              width={window.innerWidth < 550 ? 400 : 750}
              series={series}
              options={{
                labels,
                colors,
                legend: {
                  fontSize: `${window.innerWidth < 550 ? '8rem' : '16rem'}`,
                  offsetY: 70
                },
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  colors: ['#000000'],
                  width: 0.5,
                  dashArray: 0
                },
                chart: {
                  width: '50px',
                  height: '50px'
                },
                title: {
                  align: 'center',
                  margin: 30,
                  style: {
                    fontSize: `${window.innerWidth < 550 ? '1rem' : '2rem'}`,
                  },
                  text: `Avaliação (${textualGenreSelected?.genre}) - ${roomInfo.twoMonthsName}`
                }
              }}
            />
          )}
      </div>
      <div className={`new-writing-hypothesi-graph-content-info display-${displayChart}`}>
        <h1>Quantidade de alunos que foram avaliados: {textualGenreSelected?.['writing-hypothesi-result']?.studentAvailable}</h1>
        <ul className='new-writing-hypothesi-graph-content-info-list'>
          {textualGenreSelected?.['writing-hypothesi-result']?.tags.map(t => {
            return <li key={t.id}>
              <div style={{
                position: 'relative',
                content: '',
                backgroundColor: t.color,
                width: 20,
                height: 20,
                borderRadius: '100%',
                border: '2px solid black',
              }}></div> <span>{t.tag}: {t.count}</span>
            </li>
          })}
        </ul>
      </div>
    </div>
  )
}
