/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useRef } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import './style.scss';

interface InputSelectProps {
  options: string[]
  defaultValue?: string
  title: string
  id?: Array<string | undefined>
  deleteAll?: boolean
  cb?: (option: string, id?: string) => void
}

export default function InputSelect({ options, title, defaultValue, id, deleteAll, cb }: InputSelectProps) {
  const option = useRef<HTMLInputElement | null>(null);
  const [idSelected, setIdSelected] = useState<string>('');
  const [display, setDisplay] = useState<boolean>(false);

  const huddleClick = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;

    if (!target.textContent || target.tagName !== 'LI') {
      return;
    }

    if (!option.current) {
      return;
    }

    option.current.value = target.textContent;

    if (target.id.length > 0) {
      setIdSelected(target.id.split('id_')[1])
    }
    setDisplay(false);
    if (!cb) {
      return;
    }
    cb(target.textContent, target.id.split('id_')[1]);
  };

  const huddleDisplay = (e: React.MouseEvent<HTMLElement>) => {
    setDisplay(!display);
  };

  useEffect(() => {
    if (!option.current) {
      return;
    }

    option.current.value = '';
    if (!defaultValue) {
      return;
    }

    option.current.value = defaultValue;
  }, [defaultValue]);

  useEffect(() => {
    if (!defaultValue || !option.current) {
      return;
    }

    option.current.value = defaultValue;
  }, [deleteAll]);

  return (
		<div className="input-select">
			<div className="input-content-title">
				<label>{title}</label>
			</div>
			<div className='input-content-select' onClickCapture={huddleDisplay}>
				<input type="text" id={`id_${idSelected}`} ref={option} readOnly={true} disabled/>
				<div>
					<IoIosArrowDown className={`icon-arrow display-${String(display)}`} size={20} />
				</div>
			</div>
			<div className={`input-content-list-options-y display-${String(display)}`}>
				<ul onClickCapture={huddleClick}>
					{options.map((text, index) => (
						<li className="text-input-select" id={`id_${!id ? '' : (id[index] ?? '')}`}>
							{text}
						</li>
					))}
				</ul>
			</div>
		</div>
  );
}
